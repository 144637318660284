
export default {
  name: "card-embudo-venta",
  components: {
    EtiquetaChip: () => import("./../../leads/components/EtiquetaChip.vue"),
  },
  props: {
    color: { type: String, required: true },
    oportunidadObj: { type: Object, required: true },
  },
  data() {
    return {
      statusOportunidadEnProceso:
        this.constantesStatusCadena.oportunidadEnProceso,
    };
  },
  methods: {
    cardSeleccionada() {
      this.$emit("card-seleccionada", this.oportunidadObj);
    },
  },
};
